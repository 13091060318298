import { Api, makeGetUrl } from '@/libraries/api'

async function getItems(messageType = '', page = 1, limit = 10, query = '') {
  const url = `/admin/message`
  const filters = []
  filters.push(`type=${messageType}`)
  // filters.push(`start_date=2021-05-01`)
  // filters.push(`end_date=2021-08-01`)
  const params = {
    page,
    limit,
    query,
    filters: filters.join('|')
  }
  const apiResult = await Api().get(makeGetUrl(url, params))
  const { items, pagination } = apiResult.data.data
  return { items, pagination }
}

export default {
  getItems
}
