<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent :title="`메세지 발송목록(${$route.params.messageType})`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`messages-item-${item.idx}`"
              >
                <td class="text-center">
                  {{ item.created_at | dayjsLocalDatetime }}
                </td>
                <td class="text-center text-caption">
                  {{ item.id }}
                </td>
                <td class="text-center">
                  {{ item.receiver }}
                </td>
                <td class="text-center text-caption">
                  {{ item.status }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import paginationLib from '../_elements/pagination/paginationLib'
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import Api from './api'

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    return {
      isLoading: false,
      messageTypes: {
        kakao: 'ATA',
        sms: 'SMS',
        sns: 'PUSH'
      },
      items: [],
      query: '',
      pagination: {},
      headers: [
        { text: 'created at', align: 'center' },
        { text: 'id', align: 'center' },
        { text: 'receiver', align: 'center' },
        { text: 'status', align: 'center' }
      ]
    }
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      const params = this.$route.query
      if (params.page === undefined) {
        this.$router.push({ query: { page: 1 } }, () => {})
      } else {
        this.query = params.query || ''
        this.pagination.page = parseInt(params.page, 10) || 1
        this.getItems()
      }
    },
    async getItems() {
      this.isLoading = true
      const { messageType } = this.$route.params
      const { items, pagination } = await Api.getItems(
        this.messageTypes[messageType], this.pagination.page, this.pagination.limit, this.query
      )
      this.items = items
      this.pagination = paginationLib.paginationMapper(pagination)
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
